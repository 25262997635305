<script>
    import { betting_record, thirdpartyTradingHistory } from "@/api/my";    
    import { getThirdPartyList } from "@/api/home";

    export default {
        name:'TradeHistory',
        directives: {
            'infinite-scroll': app.Mint.InfiniteScroll,
        },
        components: {
            Header: () => import('components/base/Header'),

            'mt-header':app.Mint.Header,
            'mt-button':app.Mint.Button,
            'mt-actionsheet':app.Mint.Actionsheet,
            'mt-datetime-picker':app.Mint.DatetimePicker,
        },
        data() {
            return {
                header: {
                    mainTitle: getWord('trade_history2'),
                },
                thirdParty:[],
                list: null,
                pickerValue: "",                
                startTime: new Date(),
                endTime: new Date(),
                endTimeVisible: false,
                testSourcetype: [],
                inputSourcetype: ["2", "5", "9", "3", "98", "21"],
                outputSourcetype: ["10", "99", "4"],
                ddSourcetype: ["1", "23"],
                dtClassic: ["28", "29"],
                transferRecord: ['18', '19'],
                highFrequency:['34','35'],
                sourceType: { id: 1, method: this.getresult, name: getWord('pcdandan'), type: "dt_pc28" },
                sourceTypeList: [
                    { id: 0, method: this.getresult, name: getWord('all_games'), type: "" },
                    { id: 1, method: this.getresult, name: getWord('pcdandan'), type: "dt_pc28" },
                    { id: 2, method: this.getresult, name: getWord('jingdiancai'), type: "dt_classic" },
                    { id: 3, method: this.getresult, name: getWord('gaopincai'), type: "systematic" },
                    { id: 4, method: this.getresult, name: getWord('asia_games'), type: "asia" },
                ],
                sheetVisible: false,
                value1: null,
                loading_data2: true,
                page: 1,
                pageSize: 20,
                totalPage: "",
                formateDatestartTime: "",
                formateDateendTime: "",
                formateDatestartTimeStamp:null,
                formateDateendTimeStamp:null,

                totalGain:0,
                totalPay:0,

                sum_money:null,
                effective_sum_money:null,
            };
        },
        filters: {            
            sourceType(st) {},
            timestampToTime(timestamp) {
                var date = new Date(timestamp * 1000);
                var Y = date.getFullYear() + "-";
                var M =
                    (date.getMonth() + 1 < 10 ?
                        "0" + (date.getMonth() + 1) :
                        date.getMonth() + 1) + "-";
                var D = date.getDate() + " ";
                var h = date.getHours() + ":";
                var m = date.getMinutes() + ":";
                var s = date.getSeconds();
                return Y + M + D + h + m + s;
            }
        },
        methods: {
            getcolor(val) {
                if (val > 0) {
                    return "red-text";
                } else {
                    return "green-text";
                }
            },
            left_click() {
                let hisNum = parseInt(window.history.length);
                if (hisNum == 1) {
                    this.$router.push("/home");
                } else {
                    this.$router.go(-1);
                }
            },
            
            sourceTypeName(id) {
                return this.sourceTypeList["id"]["name"];
            },
            loadMore() {},
            formateDate: function(date) {
                var Y = date.getFullYear() + "-";
                var M =
                    (date.getMonth() + 1 < 10 ?
                        "0" + (date.getMonth() + 1) :
                        date.getMonth() + 1) + "-";
                var D =
                    date.getDate() + 1 < 10 ?
                    "0" + (date.getDate() + 1) :
                    date.getDate() + 1;
                return Y + M + D;
            },
            getbank() {},
            showStart: function() {
                this.$refs["startPicker"].open();
            },
            showEnd: function() {
                this.$refs["endPicker"].open();
            },
            handleConfirmStart: function(date) {
                if (new Date(date).getTime()>this.formateDateendTimeStamp) {

                }else {
                    var year = this.startTime.getFullYear();
                    var month = this.startTime.getMonth() + 1;
                    var day = this.startTime.getDate();                                
                    this.formateDatestartTime = year + "-" + this.formatTen(month) + "-" + this.formatTen(day);
                    this.formateDatestartTimeStamp= new Date(date).getTime();
                }
            },
            handleConfirmEnd: function(date) {
                if (new Date(date).getTime()<this.formateDatestartTimeStamp) {

                }else {
                    var year = this.endTime.getFullYear();
                    var month = this.endTime.getMonth() + 1;
                    var day = this.endTime.getDate();                                
                    this.formateDateendTime = year + "-" + this.formatTen(month) + "-" + this.formatTen(day);
                    this.formateDateendTimeStamp= new Date(date).getTime();
                }
            },
            showSourceType: function() {
                this.sheetVisible = true;
            },
            getresult: function(val, inx) {
                this.sourceType = val;
            },
            //搜索
            search() {
                this.list = [];
                this.page = 1;
                this.pageSize = 20;
                this.loadData();
            },
            loadData: function() {
                var that=this;
                
                var time0 = new Date(
                    new Date(new Date(this.startTime).toLocaleDateString()).getTime()
                );
                let start = new Date(time0).getTime(new Date(time0));
                start = parseInt(start.toString() / 1000);
                
                time0 = new Date(
                    new Date(new Date(this.endTime).toLocaleDateString()).getTime() +
                    24 * 60 * 60 * 1000 -
                    1
                );
                let end = new Date(time0).getTime(new Date(time0));
                end = parseInt(end.toString() / 1000);

                if ( isNaN(this.sourceType["id"]) ) {
                    var type=this.sourceType["id"]
                    thirdpartyTradingHistory(type,start,end).then(result=>{
                        if (result.data.code == "SUCCESS") {
                            this.list = result.data.result.list;
                            this.length = this.list.length;
                            this.sum_money = 0;
                            this.effective_sum_money = 0;
                            for (var i = 0; i < this.length; i++) {
                                this.sum_money =
                                    this.sum_money + parseInt(this.list[i].winLoss);
                                this.effective_sum_money =
                                    this.effective_sum_money + parseInt(this.list[i].bet);
                            }

                            if (Object.keys(that.list).length===1 && this.list.sum) {
                                this.list=[];
                                this.list.push({
                                    errorMsg:getWord('no_result_in_this_range')
                                })
                            }
                        }else {
                            app.Mint.Toast(result.data.msg);
                        }
                    })
                }else {
                    var temptype = '';
                    if (this.sourceType["id"] == "0") {
                        temptype = '';
                    }else if (this.sourceType["id"] == "1") {
                        temptype = this.sourceType["type"];
                    } else if (this.sourceType["id"] == "2") {
                        temptype = this.sourceType["type"];
                    } else if (this.sourceType["id"] == "3") {
                        temptype = this.sourceType["type"];
                    } else if (this.sourceType["id"] == "4") {
                        temptype = this.sourceType["type"];
                    }
                    betting_record(temptype,start,end,this.isProfit).then(result => {
                        if (result.data.code == "SUCCESS") {
                            if (temptype === '') {
                                this.list = result.data.result.list;
                            }else {
                                this.list = result.data.result;
                            }                            
                            this.length = this.list.length;
                            this.sum_money = 0;
                            this.effective_sum_money = 0;
                            for (var i = 0; i < this.length; i++) {
                                this.sum_money =
                                    this.sum_money + parseInt(temptype === ''?this.list[i].winLoss:this.list[i].bonus_money);
                                this.effective_sum_money =
                                    this.effective_sum_money + parseInt(this.list[i].money);
                            }

                            if (Object.keys(that.list).length===1 && that.list.sum) {
                                that.list=[];
                                this.list.push({
                                    errorMsg:getWord('no_result_in_this_range')
                                })
                            }else {
                                if (temptype === '') {
                                    that.list.push({
                                        'date':getWord('total3'),
                                        'winLoss':that.sum_money,
                                        'money':that.effective_sum_money,
                                    })
                                }
                            }
                        } else {
                            app.Mint.Toast(result.data.msg);
                        }
                    });
                }

                this.loading_data2 = false;
            },
            //分页
            loadMore_data() {
                if (this.page >= this.totalPage) {
                    return false;
                } else {
                    this.page++;
                    this.loadData();
                }
            },
            formatTen(num) {
                return num > 9 ? num + "" : "0" + num;
            },
            //转换时间
            getDay(num, str) {
                var today = new Date();
                var oYear = today.getFullYear();
                var oMoth = (today.getMonth() + 1).toString();
                var oDay = today.getDate().toString();;
                if (oMoth.length <= 1) oMoth = "0" + oMoth;
                if (oDay.length <= 1) oDay = "0" + oDay;
                return oYear + str + oMoth + str + oDay;
            }
        },
        mounted() {
            this.formateDatestartTime = this.formateDateendTime = this.getDay(-1, "-");
            this.formateDatestartTimeStamp = this.formateDateendTimeStamp = new Date(this.formateDatestartTime).getTime();

            getThirdPartyList().then(result => {
                if (result.data.code == "SUCCESS") {

                    result.data.result.forEach(item=>{
                        this.thirdParty.push({
                            name:item.name,
                            remark:item.remark,
                        })

                        this.sourceTypeList.push({ 
                            id: item.name, 
                            method: this.getresult, 
                            name: item.remark, 
                        })
                    })                    
                }
            })
        }
    };
</script>
<template>
    <div id="personal" :class="_TEMPLATE">
        <Header
            :mainTitle="header.mainTitle"
            :backButton=false
        />
        <div class="main">
            <h4>{{ getWord('select_range') }}</h4>
            <div class="filter">
                <fieldset @click="showSourceType">
                    <label>{{ getWord('select_category') }}</label>
                    <span class="select">{{sourceType['name']}}</span>
                    <img src="@@/assets/images2/user/moneychange/jt.png" class="rightArrow" />
                </fieldset>
                <fieldset @click="showStart">
                    <label>{{ getWord('start_from') }}</label>
                    <span class="select">{{formateDatestartTime}}</span>
                    <img src="@@/assets/images2/user/moneychange/jt.png" class="rightArrow" />
                </fieldset>
                <fieldset @click="showEnd">
                    <label>{{ getWord('end_in') }}</label>
                    <span class="select">{{formateDateendTime}}</span>
                    <img src="@@/assets/images2/user/moneychange/jt.png" class="rightArrow" />
                </fieldset>                
                <div class="submit">
                    <mt-button @click="search()" type="primary">{{ getWord('inquire') }}</mt-button>
                </div>
            </div>
            <table v-infinite-scroll="loadMore_data" infinite-scroll-disabled="loading_data2" infinite-scroll-distance="50" v-if="list">
                <thead>
                    <tr>
                        <th width="34%">{{ getWord(['betting2', 'date']) }}</th>
                        <th width="34%">{{ getWord('win_loss') }}</th>
                        <th width="34%">{{ getWord(['betting2', 'amount_of_betting']) }}</th>
                    </tr>
                </thead>
                <tbody>                    
                    <tr v-for="(item,i) in list" :key="i" v-if="!list[0].errorMsg">
                        <td>
                            {{i==='sum'?getWord('total3'):(item.date||item.bet_date)}}
                        </td>
                        <td :class="getcolor(item.winLoss)">{{item.winLoss}}</td>
                        <td>{{item.money||item.bet}}</td>
                    </tr>
                    <tr v-else>
                        <td colspan="4">{{item.errorMsg}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <mt-actionsheet :actions="sourceTypeList" v-model="sheetVisible"></mt-actionsheet>
        <mt-datetime-picker ref="startPicker" type="date" v-model="startTime" :year-format="'{value} '+getWord('year')" :month-format="'{value} '+getWord('month')" :date-format="'{value} '+getWord('day')" @confirm="handleConfirmStart"></mt-datetime-picker>
        <mt-datetime-picker ref="endPicker" type="date" v-model="endTime" :year-format="'{value} '+getWord('year')" :month-format="'{value} '+getWord('month')" :date-format="'{value} '+getWord('day')" @confirm="handleConfirmEnd"></mt-datetime-picker>
    </div>
</template>
<style scoped lang='scss' type="text/css">
#personal {    
    height: 100%;
    display: flex;
    flex-direction: column;

    &.template-3 {
        background-color: #EEEEEE;

        .main {

            > h4 {
                display: none;
            }

            .filter {
                background-color: #ffffff;

                fieldset {
                    border-top: 0;
                    font-size: .28rem;
                    border-bottom: 1px solid #B0B0B0;

                    label {
                        color: #B0B0B0;
                    }

                    .select {
                        color: #5F646E;
                    }

                    .rightArrow {
                        opacity: .4;
                        transform: rotate(0);
                    }
                }

                .submit {

                    button {
                        background-color: #EC2829;
                        height: auto;
                        padding: .2rem;

                        label {
                            font-size: .34rem;
                        }
                    }
                }
            }

            table {
                background-color: transparent;

                tbody {

                    tr {

                        td {
                            background-color: #ffffff;
                            border-top: 1px solid #B0B0B0;
                        }

                        &:first-child {

                            td {
                                border-top: 0;
                            }
                        }
                    }
                }

                th {
                    color: #B0B0B0;
                    font-size: .3rem;
                    font-weight: normal;
                    border: 0;
                }

                td {
                    border:0;
                    font-size: .28rem;
                    color: #333333;
                }
            }
        }

        .mint-datetime {

            /deep/ .mint-datetime-picker {

                .picker-items {

                    .picker-item {
                        border-top:1px solid #eeeeee;
                    }

                    .picker-center-highlight {
                        display: none;
                    }
                }

                .picker-toolbar {
                    display: flex;
                    height: auto;

                    span {
                        font-size: .36rem;
                        float: none;
                        line-height: 1rem;

                        &.mint-datetime-cancel {
                            color: #5F646E;                        
                            text-align: left;
                            padding-left: .5rem;
                        }

                        &.mint-datetime-confirm {
                            text-align: right;
                            padding-right: .5rem;
                        }
                    }
                }
            }
        }
    }

    .main {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        background-color: #EFEFEF;

        h4 {
            font-size: .3rem;
            color: #999999;
            margin-left: .4rem;
            padding-top: .4rem;
            padding-bottom: .2rem;
        }

        .filter {
            
            fieldset {
                border:0;
                background-color: #ffffff;
                border-top: 1px solid #cccccc;
                font-size: .3rem;
                height: .9rem;
                line-height: .9rem;
                position: relative;

                &:first-child {
                    border-top:0;
                }

                label {
                    color: #999999;
                    margin-left: .4rem;
                    width: 25%;
                    display: inline-block;
                    white-space: nowrap;
                }

                .select {

                }

                .rightArrow {
                    position: absolute;
                    right: 0.45rem;
                    top: 0.3rem;
                    width: 0.18rem;
                    height: 0.33rem;
                    filter: grayscale(100%);
                    opacity: .7;
                    transform: rotate(90deg);
                }
            }

            div.submit {
                padding:0 .4rem;
                margin:.4rem 0;

                button {
                    display: block;
                    width: 100%
                }
            }
        }

        table {
            border-collapse: collapse;
            background-color: white;
            font-size: 0.3rem;
            text-align: center;
            width: 100%;

            .red-text {
                color: red!important;
            }

            .green-text {
                color: green!important;
            }

            th {    
                color: rgba(71, 150, 252, 1);
                border-left: 1px solid rgba(187, 187, 187, 1);
                padding: .25rem 0;
                
                &:first-child {
                    border-left:0;
                }
            }

            td {
                border-left: 1px solid rgba(187, 187, 187, 1);
                border-top: 1px solid rgba(187, 187, 187, 1);
                font-size: 0.24rem;
                color: rgba(102, 102, 102, 1);
                padding: .25rem 0;
                
                &:first-child {
                    border-left:0;
                }
            }
        }
    }
}
</style>
